$b:#000000;
$w:#ffffff;
$orange: #e65325;
$appbgcolor: #ffffff;
/*Header*/
$headerBackgroundColor: #DCE4E7;

/*Anzeigen Farben*/
$listbg: #DBC5A5;
$libghover: #E5D9C7;
$labelColor: #000000;

/*Schriftfarben*/
$mainheadlinecolor: $orange;
$secondHeadlinecolor: #20262C;
$thirdHeadLinecolor:#20262C;

$joblistborderdcolor: $orange;
$joblisthovercolor: rgba(230,83,37,.3);
$joblistlinkcolor: $b;
/*Footer*/
$footerBackgroundColor: #DCE4E7;
$footerlinkcolor:#484A4F;
$inputBordderColor:#c5c8d3;

body{
  margin:0;
  padding:0;
}

:root{
  --content-uns-background: #DCE4E7;
  --content-uns-sublinecolor: #484A4F;
  --footer-background-color:#DCE4E7;
  --footer-linkcolor: #484a4f;
  --job-detail-h5color:#20262c;
  --job-detail-h2color:#0446BE;

  --job-detail-background-color: $w;
  --job-detail-imageboxbackground-color: $w;
  --job-location-backgroundcolor:#DCE4E7;
  --job-location--textcolor:$orange;
  --jobduration-mainheadline-color: #000000;
  --jobdetailheadline-mainheadline-color: #575D64;
  --jobtodos-headline-color:$orange;
  --joboffercontent-backgroundcolor: #DCE4E7;
  --joboffercontent-headlinecolor:#484A4F;
  --joboffercontent-contentcolor:#484A4F;
  --joboffercontent-ctabackgroundcolor:$orange;
  --joboffercontent-ctabackhovergroundcolor:lighten($orange,5%);
  --joboffercontent-ctacontentcolor:$w;

  --ctabutton-background-color:#E65325;
--ctabutton-background-hover-color:lighten(#E65325,5%);
--ctabutton-textcolor:$w;

  --form-contentcolor: #484a4f;
  --form-answer-backgroundcolor:$orange;
  --form-answer-backgroundhovercolor:lighten(#E65325,5%);;
  --form-answer-textcolor:$w;
  --form-label-color:$b;
  --form-border-color:#c5cbd3;
  --form-headline-color:#484a4f;
  --form-dse-linkcolor: $b;

}
