@import "../laodercss";

.formlogicwrapper
{
  width:100%;
  @include margin-top;
}

.questionarea{
  text-align: center;
  width:100%;
color:var(--form-contentcolor);
  h1{
    color:inherit;
    margin:0;
    text-align: center;

  @include fontSize(30);
    @include lineHeight(40);
    @include tablet-por{
      @include fontSize(40);
      @include lineHeight(50);
      margin-bottom:50px;

    }
  }

}
ul.answerlist{
  list-style-type: none;
padding:0;
  max-width:570px;
  margin:50px auto;
  color:var(--form-answer-textcolor);
  @include tablet-por {
    margin: 30px auto 125px auto;
  }
    li{
  background-color: var(--form-answer-backgroundcolor);
    cursor: pointer;
    @include bs();
      padding: 18px 30px;
border-radius: 5px;
      text-align: left;

      @include tablet-por {
        padding: 25px 30px;
      }
    margin-bottom:25px;
    @include setTransition();
    &:hover{
background-color: var(--form-answer-backgroundhovercolor);;
    }
  }
}
.userformwrapper{
  h1 {
    text-align: center;
  }
  position:relative;
  max-width:800px;
  margin:0 auto 150px auto;

  @include tablet-por{
    margin:0 auto 200px auto;
  }

  .userform{
    max-width:570px;
    margin:0 auto 50px auto;
@include tablet-por{
  margin:0 auto 100px auto;
}

    label{
      color: $labelColor;
      @include fontSize(18);
      display:block;
      @include lineHeight(25);
      padding-bottom:10px;
    }
   input:not(input[type=checkbox]){
display:block;
     width:100%;
     margin-bottom:25px;
     padding:15px 25px;
     @include fontSize(18);
      max-width:570px;
     height:50px;
     box-sizing: border-box;
     border-radius:5px;
     border:1px solid;

     border-color: #c5cBD3 ;
     box-shadow: none;
     text-shadow:none;
outline: none!important;
     *:focus {
       outline: 0 !important;
     }
   }
a{
  text-decoration: none;
  color:#004F9F;
}
  }
button{
  color:var(--ctabutton-textcolor);
  text-decoration: none;
  background-color: var(--ctabutton-background-color);
  border-radius: 5px;
  font-size: 18px;
  line-height: 21px;
  text-transform: uppercase;
  padding:17px 35px 14px 35px;
  font-weight: bold;
  border: none;
  text-shadow: unset;
  position:absolute;
  outline:unset;
  left:50%;
  transform: translateX(-50%);
  @include setTransition();
cursor: pointer;
  &:hover{
    background-color:var(--ctabutton-background-hover-color);
  }


}
}
.formsubmitcontent{
  min-height:550px;
  h1{
    margin-top:125px;
    margin-bottom:25px;
  }
  p{
    text-align: center;
    max-width:570px;
    margin:0 auto 20px auto;

  }
}
.textarea{

  display:block;
  width:100%;
  max-width:600px;
  height:200px;
  overflow:hidden;
  box-sizing: border-box;
  padding:10px;
  border-radius: 5px;
  border-color:$inputBordderColor;
  font-family: "Roboto",sans-serif;
   margin:30px auto;
}
.ctabutton{

  color:var(--ctabutton-textcolor);
    text-decoration: none;
  background-color: var(--ctabutton-background-color);
    border-radius: 5px;
    font-size: 18px;
border:none;
    line-height: 21px;
    text-transform: uppercase;
    padding:17px 35px 14px 35px;
    font-weight: bold;
  border-color: var(--ctabutton-background-color);
  cursor: pointer;
  @include setTransition();

  &:hover{
    background-color:var(--ctabutton-background-hover-color);
  }

}
.checkdse{
  display:inline!important;
  width:25px!important;


}
.dsetext{
  position:relative;
  top:-1px;
  left:5px;
  white-space: nowrap;
  display:inline-block;
  width:200px;
  margin-bottom:20px;
  a{
    color:var(--form-dse-linkcolor)!important;
  }
}
.userformcontent{
  h1{
    color:var(--form-headline-color);
  }
}