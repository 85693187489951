@import "../laodercss";


.videoframe{

  max-width: 1170px;
  margin:0 auto 50px auto;
@include tablet-max{
  margin:0 auto 0px auto;
}

}




.modawlrapper{
  position: fixed;
  width:100%;
  z-index:999999999999999999;
  height:100%;
  top:0;
  left:0;
  background-color:rgba(99,99,99,0.8);


}
.videplayercontainer{
  width:100%;
  max-width:800px;
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  video{
    width:100%;
    height:100%;

  }
}
.closebutton{
  display:block;
  width:30px;
  height:30px;
  border:2px solid #ffffff;
  font-weight:bold;
  box-sizing: border-box;
  border-radius: 50%;
  color:#ffffff;
  text-align: center;
  padding-top:4px;
  position:relative;
  left:91%;
  cursor:pointer;
  top:-10px;
}
@media screen and (min-width:767px){
  .closebutton {
    left: 95%;
  }

}
@media screen and (min-width:860px){
  .closebutton {
    left: 99%;
  }

}
.videocontentframe{

  max-width:$maxwidth;
  margin-inline:auto;
display:grid;


 @media screen and (min-width:767px) {
   grid-template-columns: 1fr 1fr;

 }
  @include tablet-max{
    grid-template-columns: 760px 1fr;

  }
  gap:0 50px;
align-items: center;
  justify-items: center;
  .videcontent{
    padding:0 13px;
    @media screen and (min-width:1100px) {
      padding:0;
    }
    h2{
      font-family: "Roboto",sans-serif;
      font-weight: bold;
      color: $orange;
      @include fontSize(40);
      @include lineHeight(50);

    }
  }
  .buttonframe{
margin-block: 50px;

    @media screen and (min-width:767px) {
margin-block:0;
    }
    button{
      border:2px solid $orange;
      width:90px;
      height:90px;
      cursor: pointer;

      border-radius: 50%;
      background-color: transparent;
position:relative;
      top:0px;
      span{
        display:grid;
        align-content: center;
        justify-content: center;
      img{
        padding-inline-start: 10px;
      }
      }
&:after{
  content: "";
  display: block;
  border: 2px solid transparent;
  position: absolute;
  height: 100%;
  width: 100%;
  top: -2px;
  left: -2px;
  border-radius: 50%;
  animation: anibutton 1.5s infinite;
}


&:before{

  content: "";
  display: block;
  border: 2px solid transparent;
  position: absolute;
  height: 100%;
  width: 100%;
  top: -40px;
  left: 0;
  border-radius: 50%;
}

    }
  }
}
@keyframes anibutton{
  0% {
    transform: scale(1);
    opacity:1;
    border-color:$orange;
  }
  100% {
    transform: scale(1.5);
    border-color:$orange;
    opacity: 0;
  }

}