@import "../laodercss";

.mastercontent{
  margin-left:13px;
  margin-right:13px;
  margin-bottom: 50px;
  @include tablet-por{
    margin-bottom:100px;
  }
  @include tablet-max{
    margin-bottom:125px;
  }
  @include desk-max{
    margin-left:0;
    margin-right:0;
  }
  h1{
    font-family: 'Roboto',sans-serif;
    font-weight: bold;
    @include fontSize(40);
    @include lineHeight(50);
    color:$mainheadlinecolor;
hyphens: auto;
    h2{
      font-family: 'Roboto',sans-serif!important;
      font-weight: bold;

    }
    h3{
      @include fontSize(30);
      @include lineHeight(35);
      font-weight: bold;
      color:#000000;

    }
  }
  h5{
    @include fontSize(16);
    @include lineHeight(20);
    letter-spacing: 1px;
    font-weight: 300;
    color:#20262C;
    text-transform: uppercase;

  }
  p{
    @include fontSize(18);
    @include lineHeight(25);
    color:$thirdHeadLinecolor;
  }
}

.inlay{
  max-width: $maxwidth;
  margin: 0 auto;
}
.flexrow{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.homecontent{

}

.joblistcenter{
  @include tablet-max {
    justify-content: center!important;
  }
}

.joblist{
  width:100%;
  -webkit-box-flex:100%;
  -ms-flex:100%;
  flex:100%;

  margin:0;
  padding:0;
  @include tablet-max{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    gap:0 30px;
    max-width:740px;
    margin-left: auto;
    margin-right: auto;
  }


  li{
    list-style-type: none;
    border: 2px solid $joblistborderdcolor;
    text-align: center;
    border-radius: 15px;
    margin-bottom:25px;
    height:120px;
    @include setTransition();
    position: relative;
    @include tablet-por{
      max-width: 370px;
      margin-left: auto;
      margin-right:auto;

    }
    @include tablet-max{

      margin-left:unset;
      margin-right:unset;
      -webkit-box-flex:50%;
      -ms-flex:50%;
      flex:50%;


    }
    &:hover{
      background-color:$joblisthovercolor;
    }
    a{
      @include fontSize(20);
      text-decoration: none;
      color:$joblistlinkcolor;
      font-weight:700;
      text-align: center;

      position:absolute;
      top:50%;
      left:50%;
      -webkit-transform: translate(-50%,-50%);
      -ms-transform: translate(-50%,-50%);
      transform: translate(-50%,-50%);

      width:90%;
      @include tablet-max{
        width:100%;
      }
    }
  }
}
.joblistheadline{
  width:100%;
  @include fontSize(40);
  text-align: center;
  color:$mainheadlinecolor;
  margin-bottom: 50px;
  @include margin-top;
}
